<template>
  <v-container fill-height fluid grid-list-xl pb-5 mb-5>
    <v-layout column white wrap ma-2>
      <!-- CUSTOMERS -->
      <v-flex px-4>
        <h2>
          Clients
        </h2>
      </v-flex>
      <v-flex>
        <v-flex right py-0>
          <v-btn
            v-if="isAdmin"
            :disabled="!isAdmin"
            center
            class="success"
            to="/customer/create"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
            <v-flex class="ml-2 pa-0">
              Ajouter un client
            </v-flex>
          </v-btn>
        </v-flex>
      </v-flex>
      <material-DataTableCustomer
        v-if="customers"
        :customers="customers"
        can-search
        @editRow="editCustomer($event)"
        @deleteRow="
          isDeletingCustomer = true;
          deletingId = $event.id;
        "
      />

      <!-- DELETE DIALOG BOX -->
      <!-- deleteCustomer($event) -->
      <ui-DialogBox
        title="Suppression d'un client"
        msg="Voulez-vous vraiment supprimer le client ainsi que tous les projets et utilisateurs qui lui sont liés ?"
        :show="isDeletingCustomer"
        icon-title="mdi-delete-outline"
        cancel-icon="close-circle-outline"
        ok-icon="check-circle-outline"
        @cancel="isDeletingCustomer = false"
        @ok="
          isDeletingCustomer = false;
          deleteCustomer(deletingId);
        "
      />
      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SnackBarMixin from '@/mixins/SnackBarMixin';

export default {
  name: 'CustomerList',

  mixins: [SnackBarMixin],

  data: () => ({
    isDeletingCustomer: false,
    deletingId: 0,
  }),

  computed: {
    ...mapGetters('customer', ['getCustomers']),

    customers() {
      return this.getCustomers();
    },
  },

  mounted() {
    // get the customer informations to display in cards
    this.getDataCustomers();
  },

  methods: {
    ...mapActions('customer', ['get_customers', 'delete_customer_by_id']),
    ...mapMutations('app', ['showLoader']),

    getDataCustomers() {
      this.showLoader();
      this.get_customers()
        .then((response) => {
          console.log('Customers OK', response);
        })
        .catch((error) => {
          console.log('Erreur', error);
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Obtention des Clients impossible : ' + error.message,
          });
        })
        .finally(() => {
          this.showLoader();
        });
    },

    editCustomer(row) {
      console.log('editCustomer', row.id);
      this.$router.push({ path: `/customer/${row.id}/edit` });
    },

    deleteCustomer(id) {
      console.log('deleteCustomer', id);
      this.showLoader();
      this.delete_customer_by_id(id)
        .then((response) => {
          console.log('Customers OK', response);
        })
        .catch((error) => {
          console.log('Erreur', error);
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Suppression du client impossible : ' + error.message,
          });
        })
        .finally(() => {
          this.showLoader();
        });
    },
  },
};
</script>
